.Card {
  background: $Component-background;
  border: $Component-border;
  border-radius: $Component-borderRadius;
  box-shadow: $Component-boxShadow;
  color: $Component-color;
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $Content-gutter;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  > img {
    border-radius: $Component-borderRadius $Component-borderRadius 0 0;
    object-fit: cover;
    width: 100%;
  }

  &-action {
    align-self: flex-end;
    margin-top: auto;
  }

  &-text {
    > :last-child {
      margin-bottom: 0;
    }
  }

  &-title {
    @include font-size($Heading2-fontSize);

    > span {
      display: block;
      font-size: 85%;
      font-weight: normal;
    }
  }
}

.MaintenancePage {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (min-width: 1200px) {
    &::after,
    &::before {
      background: #d4f2f9;
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
    }

    &::after {
      bottom: 0;
      height: 37.5%;
      right: 0;
      width: 50%;
    }

    &::before {
      height: 25rem;
      left: 0;
      top: 0;
      width: 35rem;
    }
  }

  &-content {
    background: #fff;
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 0.5rem rgb(43 43 43 / 10%);
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    width: 80%;
  }

  &-logo {
    flex-shrink: 0;
    margin: 1.5rem auto;
  }
}

.Avatar {
  align-items: center;
  display: inline-flex;
  gap: calc($Content-gutter * 0.5);
  text-decoration: none;

  &-fallback {
    align-items: center;
    background: $Icon-background;
    border-radius: 1.5rem;
    color: $Icon-color;
    display: inline-flex;
    flex-shrink: 0;
    font-size: $Strong-fontSize;
    font-weight: bold;
    height: 3rem;
    justify-content: center;
    text-decoration: none;
    width: 3rem;
  }

  &-text {
    font-weight: normal;
  }
}

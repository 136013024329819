.AutoComplete {
  &-trigger {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: calc($Content-gutter / 4 * -1) 0 0 calc($Content-gutter / 2 * -1);
    padding: calc($Content-gutter / 4) calc($Content-gutter / 2);

    > div {
      margin: calc($Content-gutter / 4) 0 0 calc($Content-gutter / 2);
    }

    > input {
      border-color: transparent;
      flex-grow: 1;
      margin: calc($Content-gutter / 4) 0 0 calc($Content-gutter / 2);
      padding: calc($Content-gutter / 4) calc($Content-gutter / 2);
      width: auto;
    }
  }
}
